import React, { useState, createContext } from "react";
import { Auth as MediastoreAuth } from "@cleeng/mediastore-sdk";

export const AuthContext = createContext();

const AuthContextProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    MediastoreAuth.isLogged()
  );

  const setAuth = (newAuthStatus) => {
    setIsAuthenticated(newAuthStatus);
  };

  //Auth API logic here//

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, setIsAuthenticated, setAuth }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
