import React, { useContext } from "react";
import { PasswordReset as PasswordResetComponent } from "@cleeng/mediastore-sdk";

const PasswordReset = (props) => {
  return (
    <div>
      <h1>PasswordReset</h1>
      <div id="Sub-Component-PasswordReset">
        <PasswordResetComponent onSuccess={() => console.log("success")} />
      </div>
    </div>
  );
};

export default PasswordReset;
