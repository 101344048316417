import React, { useContext } from "react";
import {
  Login as LoginComponent,
  Auth as MediastoreAuth,
} from "@cleeng/mediastore-sdk";
import { AuthContext } from "Context/AuthContext";

const Login = (props) => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

  React.useEffect(() => {
    MediastoreAuth.isLogged();
  }, [isAuthenticated]);
  return (
    <div>
      {MediastoreAuth.isLogged() ? (
        <>
          <h2 data-cy="loggedInMessage">
            In order to see Login component you need to be logged out. <br></br>
            Please logout.
          </h2>
        </>
      ) : (
        <>
          <h1>Login</h1>
          <div id="Sub-Component-Login">
            <LoginComponent
              onSuccess={setIsAuthenticated}
              onPasswordResetClick={() =>
                (window.location.href =
                  "https://mssdk-demo-app.stardustlab.com/PasswordReset")
              }
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Login;
