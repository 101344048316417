import React, { useContext } from "react";
import {
  PlanDetails as PlanDetailsComponent,
  Auth as MediastoreAuth,
} from "@cleeng/mediastore-sdk";
import { AuthContext } from "Context/AuthContext";

const customCancellationReasons = [
  {
    value: "customCancellationReasons test",
    key: "custom-cancellation-reason.poor-customer-support",
  },
  {
    value: "Switch to a different service",
    key: "custom-cancellation-reason.switch-to-different-service",
  },
];

const PlanDetails = (props) => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

  React.useEffect(() => {
    MediastoreAuth.isLogged();
  }, [isAuthenticated]);
  return (
    <div>
      {MediastoreAuth.isLogged() ? (
        <>
          <h1>PlanDetails</h1>
          <div id="Sub-Component-PlanDetails">
            <PlanDetailsComponent
              skipAvailableFreeExtensionStep
              displayGracePeriodError
              customCancellationReasons={customCancellationReasons}
            />
          </div>
        </>
      ) : (
        <>
          <h1>
            In order to see PlanDetails component you need to be logged in.{" "}
            <br></br>Please login.
          </h1>
        </>
      )}
    </div>
  );
};

export default PlanDetails;
