import React, { useContext } from "react";
import {
  CheckoutConsents as CheckoutConsentsComponent,
  Auth as MediastoreAuth,
} from "@cleeng/mediastore-sdk";
import { AuthContext } from "Context/AuthContext";

const CheckoutConsents = (props) => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

  React.useEffect(() => {
    MediastoreAuth.isLogged();
  }, [isAuthenticated]);
  return (
    <div>
      {MediastoreAuth.isLogged() ? (
        <>
          <h1>CheckoutConsents</h1>
          <div id="Sub-Component-CheckoutConsents">
            <CheckoutConsentsComponent />
          </div>
        </>
      ) : (
        <>
          <h2>
            In order to see CheckoutConsents component you need to be logged in.{" "}
            <br></br>Please login.
          </h2>
        </>
      )}
    </div>
  );
};

export default CheckoutConsents;
