import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import "../App.css";
import { AuthContext } from "Context/AuthContext";
import { Auth as MediastoreAuth } from "@cleeng/mediastore-sdk";
import Dropdown from "./Dropdown";

const NavitgationBar = (props) => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);
  const [dropdown, setDropdown] = useState(false);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    setDropdown(false);
  };

  React.useEffect(() => {
    MediastoreAuth.isLogged();
  }, [isAuthenticated]);

  return (
    <div className="AppAuth">
      <header className="menu">
        <p className="menu-link">
          <button
            className="button"
            onClick={() => props.setToggleModalVisibility(true)}
          >
            Change Config
          </button>
        </p>

        <ul className="menu">
          <li onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
            <Link
              to="/allLowerComponents"
              className="nav-links"
              data-cy="allLowerComponents"
              // onClick={}
            >
              allLowerComponents
            </Link>
            {dropdown && <Dropdown />}
          </li>
          <li>
            <Link className="nav-links" to="/checkout">
              CheckOut
            </Link>
          </li>
          <li>
            <Link className="nav-links" to="/myaccount">
              MyAccount
            </Link>
          </li>
          <li className="button-adjustment">
            <button
              className="button-red"
              onClick={() => {
                MediastoreAuth.logout();
                window.location.reload(false);
              }}
              data-cy="logoutButton"
            >
              Logout
            </button>
            <button
              className="button-orange"
              onClick={() => {
                localStorage.removeItem("CLEENG_REFRESH_TOKEN");
                localStorage.removeItem("CLEENG_AUTH_TOKEN");
                window.location.reload(false);
              }}
              data-cy="logoutButton"
            >
              Clear
            </button>
          </li>
        </ul>
      </header>
    </div>
  );
};
export default NavitgationBar;
