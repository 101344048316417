import React from "react";
import { Checkout } from "@cleeng/mediastore-sdk";
// import adyenConfiguration from "Context/adyenConfiguration";
import { useSearchParams } from "react-router-dom";

const CheckOut = ({ offer }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  return (
    <div>
      <h1>Checkout</h1>
      <div id="Sub-Component-Checkout">
        <Checkout offerId={offer} hideCaptcha={true} />
      </div>

      {/* <button  onClick={() => setSearchParams({lng: "fr",})}>fr</button> */}
    </div>
  );
};

export default CheckOut;
