import React from "react";
import { MyAccount as CleengMyAccount } from "@cleeng/mediastore-sdk";
// import adyenConfiguration from "Context/adyenConfiguration";
const customCancellationReasons = [];
const MyAccount = () => {
  return (
    <div>
      <h1>MyAccount</h1>
      <div id="UpperComponentMyAccount">
        <CleengMyAccount
          displayGracePeriodError
          customCancellationReasons={customCancellationReasons}
          // adyenConfiguration={adyenConfiguration}
        />
      </div>
    </div>
  );
};

export default MyAccount;
