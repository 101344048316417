import React, { useContext } from "react";
import { Config } from "@cleeng/mediastore-sdk";
import {
  UpdateProfile as UpdateProfileComponent,
  Auth as MediastoreAuth,
} from "@cleeng/mediastore-sdk";
import { AuthContext } from "Context/AuthContext";

const UpdateProfile = (props) => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

  React.useEffect(() => {
    MediastoreAuth.isLogged();
  }, [isAuthenticated]);
  return (
    <div>
      {MediastoreAuth.isLogged() ? (
        <>
          <h1>UpdateProfile</h1>
          <button
            onClick={() => {
              Config.setLanguage("ar");
              console.log("Config.setLanguage(ar)");
            }}
          >
            ar
          </button>
          <button
            onClick={() => {
              Config.setLanguage("en");
              console.log("Config.setLanguage(en)");
            }}
          >
            en
          </button>
          <div id="Sub-Component-UpdateProfile">
            <UpdateProfileComponent />
          </div>

          <iframe
            id="inlineFrameExample"
            title="Inline Frame Example"
            width="300"
            height="200"
            src="https://www.openstreetmap.org/export/embed.html?bbox=-0.004017949104309083%2C51.47612752641776%2C0.00030577182769775396%2C51.478569861898606&layer=mapnik"
          ></iframe>
        </>
      ) : (
        <>
          <h1>
            In order to see UpdateProfile component you need to be logged in.{" "}
            <br></br>Please login.
          </h1>
        </>
      )}
    </div>
  );
};

export default UpdateProfile;
