import React, { useContext } from "react";
import {
  SubscriptionSwitches as SubscriptionSwitchesComponent,
  Auth as MediastoreAuth,
} from "@cleeng/mediastore-sdk";
import { AuthContext } from "Context/AuthContext";

const SubscriptionSwitches = (props) => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

  React.useEffect(() => {
    MediastoreAuth.isLogged();
  }, [isAuthenticated]);
  return (
    <div>
      {MediastoreAuth.isLogged() ? (
        <>
          <h1>SubscriptionSwitches</h1>
          <div id="Sub-Component-SubscriptionSwitches">
            <SubscriptionSwitchesComponent offerId={props.offer} />
          </div>
        </>
      ) : (
        <>
          <h1>
            In order to see SubscriptionSwitches component you need to be logged
            in. <br></br>Please login.
          </h1>
        </>
      )}
    </div>
  );
};

export default SubscriptionSwitches;
