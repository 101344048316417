const adyenConfiguration = {
  checkoutReturnUrl:
    'https://mssdk-demo-app.stardustlab.com/CheckOutWithExtraProps', // URL that should be passed for checkout create payment session and initial payment,
  myaccountReturnUrl: 'https://mssdk-demo-app.stardustlab.com/myaccount',
  analytics: {
    enabled: false // Indicates if you're sending analytics data to Adyen. Default: true.
  },
  paymentMethodsConfiguration: {
    card: {
      name: 'Credit/Debit Card',
      billingAddressRequired: false,
      billingAddressMode: 'partial',
      brands: ['visa', 'amex', 'mc'],
      showBrandIcon: true,
      showBrandsUnderCardNumber: true,
      positionHolderNameOnTop: false,
      styles: {
        base: { color: 'white' },
        error: { color: 'white' }
      },
      billingAddressAllowedCountries: ['US', 'CA', 'BR', 'PL'],
      autoFocus: true,
      minimumExpiryDate: '05/26'
    },
    googlePay: {
      buttonColor: 'white',
      buttonType: 'subscribe',
      // buttonLocale: lang,
      buttonSizeMode: 'fill'
    },
    applePay: {
      buttonColor: 'white-with-line',
      buttonType: 'subscribe'
    }
  },
  openFirstPaymentMethod: false,
  locale: 'string', // [en|ar] -> English | Arabic
  translations: {
    'en-US': {
      'creditCard.holderName.invalid': 'Cardholder name is not valid@@@@',
      'error.va.sf-cc-dat.01': 'Expiry date is in the past'
    }
  }
};

// {
//   checkoutReturnUrl: "http://localhost:3000/checkout", // URL that should be passed for checkout create payment session and initial payment,
//   myaccountReturnUrl: "http://localhost:3000/myaccount", // URL that should be passed for myaccount create payment session and add payment details,
//   analytics: {
//     enabled: true, // our default: true analytics data for Adyen
//   },
//   paymentMethodsConfiguration: {
//     card: {
//       name: "Credit Card", //  String that is used to display the payment method name to the shopper.
//       billingAddressRequired: false, // our default: false
//       billingAddressMode: "full", //our default: full. If billingAddressRequired is set to true, you can set this to partial to require the shopper's postal code instead of the full address.
//       // brands: ["visa", "mc"], // our default: skip this value. Array of card brands that will be recognized, default ['mc','visa','amex']
//       // brandsConfiguration: {
//       //   // our default: skip this value
//       //   visa: {
//       //     icon: "https://www.cleeng.com",
//       //   },
//       //   mc: {
//       //     icon: "https://www.cleeng.com",
//       //   },
//       //   amex: {
//       //     icon: "https://www.cleeng.com",
//       //   },
//       // },
//       showBrandIcon: true, // our default: skip this value / true. Set to false to not show the brand logo when the card brand has been recognized.
//       showBrandsUnderCardNumber: true, //our default: skip this value / true.Shows brand logos under the card number field when the shopper selects the card payment method.
//       positionHolderNameOnTop: false, //our default: skip this value / false. Renders the cardholder name field at the top of the payment form.
//       styles: {}, // our default: skip this value. Set a style object to customize the card input fields. https://docs.adyen.com/payment-methods/cards/custom-card-integration#styling
//       // billingAddressAllowedCountries: ['US', 'CA', 'BR', 'PL'], // our default: skip this value. Specify allowed country codes for the billing address. For example, ['US', 'CA', 'BR'].
//       minimumExpiryDate: "05/26", // our default: skip this value. If a shopper enters a date that is earlier than specified here, they will see the following error: "Your card expires before check out date." Format: mm/yy
//       autoFocus: true, //our default: skipt this value / true. Automatically move the focus from date field to the CVC field. The focus also moves to the date field when the entered card number reaches the expected length.
//     },
//     bancontactCard: {
//       hasHolderName: true,
//       positionHolderNameOnTop: true,
//     },
//     googlePay: {
//       buttonColor: "white",
//       buttonType: "donate",
//     },
//     applePay: {
//       buttonColor: "white",
//       // buttonType: "donate",
//     },
//     // googlePay: {
//     //   buttonColor: "white",
//     //   buttonType: "donate",
//     // },
//   },
//   locale: "string", // selects the default translations for drop-in, https://docs.adyen.com/online-payments/web-drop-in/customization#supported-languages,
//   translations: {
//     "en-US": {
//       "creditCard.holderName.invalid": "Cardholder name is not valid@@@@",
//       "error.va.sf-cc-dat.01": "Expiry date is in the past",
//     },
//   }, //  specify the locale you want to customize, and add key-value pairs corresponding to any text you want to customize. Add details instruction for this, based on the adyen doc (attached above)
//   openFirstPaymentMethod: false, // our default: true. This is a config for dropin (as onSelect fn)
// };

export default adyenConfiguration;
