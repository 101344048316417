import React, { useContext } from "react";
import {
  Subscriptions as SubscriptionsComponent,
  Auth as MediastoreAuth,
} from "@cleeng/mediastore-sdk";
import { AuthContext } from "Context/AuthContext";

const customCancellationReasons = [
  {
    value: "customCancellationReasons test",
    key: "custom-cancellation-reason.poor-customer-support",
  },
  {
    value: "Switch to a different service",
    key: "custom-cancellation-reason.switch-to-different-service",
  },
];

const Subscriptions = (props) => {
  const { isAuthenticated, setIsAuthenticated } = useContext(AuthContext);

  React.useEffect(() => {
    MediastoreAuth.isLogged();
  }, [isAuthenticated]);
  return (
    <div>
      {MediastoreAuth.isLogged() ? (
        <>
          <h1>Subscriptions</h1>
          <div id="Sub-Component-Subscriptions">
            <SubscriptionsComponent
              customCancellationReasons={[
                {
                  value: "customCancellationReasons test",
                  key: "custom-cancellation-reason.poor-customer-support",
                },
                {
                  value: "Switch to a different service",
                  key: "custom-cancellation-reason.switch-to-different-service",
                },
              ]}
            />
          </div>
        </>
      ) : (
        <>
          <h1>
            In order to see Subscriptions component you need to be logged in.{" "}
            <br></br>Please login.
          </h1>
        </>
      )}
    </div>
  );
};

export default Subscriptions;
