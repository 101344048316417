import React, { useContext, useState } from "react";
import { Config } from "@cleeng/mediastore-sdk";
import {
  Register,
  Login,
  PasswordReset,
  Purchase,
  Subscriptions,
  SubscriptionSwitches,
  PlanDetails,
  PaymentInfo,
  TransactionList,
  UpdateProfile,
  CheckoutConsents,
  Capture,
  Checkout,
} from "@cleeng/mediastore-sdk";
import { AuthContext } from "Context/AuthContext";
import { Auth as MediastoreAuth } from "@cleeng/mediastore-sdk";
import PropTypes from "prop-types";

const OnePageMS = ({ offer, refreshPage }) => {
  const { setIsAuthenticated } = useContext(AuthContext);
  const [
    toggleRegisterComponentsVisibility,
    setToggleRegisterComponentsVisibility,
  ] = useState(false);

  return (
    <div>
      {MediastoreAuth.isLogged() ? (
        <>
          <button
            onClick={() => {
              Config.setLanguage("ar");
              console.log("Config.setLanguage(ar)");
            }}
          >
            ar
          </button>
          <button
            onClick={() => {
              Config.setLanguage("en");
              console.log("Config.setLanguage(en)");
            }}
          >
            en
          </button>
          {toggleRegisterComponentsVisibility && (
            <>
              <h1>Capture</h1>
              <div id="Sub-Component-Capture">
                <Capture
                  onSuccess={() => setToggleRegisterComponentsVisibility(false)}
                />
              </div>

              <h1>CheckoutConsents</h1>
              <div id="Sub-Component-CheckoutConsents">
                <CheckoutConsents
                  onSuccess={() => console.log("success - CheckoutConsents")}
                />
              </div>
            </>
          )}
          <h1>Purchase</h1>
          <div id="Sub-Component-Purchase">
            <Purchase offerId={offer} onSuccess={() => refreshPage()} />
          </div>
          <h1>Subscriptions</h1>
          <div id="Sub-Component-Subscriptions">
            <Subscriptions />
          </div>
          <h1>SubscriptionSwitches</h1>
          <div id="Sub-Component-SubscriptionSwitches">
            <SubscriptionSwitches offerId={offer} />
          </div>
          <h1>PlanDetails</h1>
          <div id="Sub-Component-PlanDetails">
            <PlanDetails displayGracePeriodError />
          </div>

          <h1>PaymentInfo</h1>
          <div id="Sub-Component-PaymentInfo">
            <PaymentInfo displayGracePeriodError />
          </div>
          <h1>TransactionList</h1>
          <div id="Sub-Component-TransactionList">
            <TransactionList />
          </div>
          <h1>UpdateProfile</h1>
          <div id="Sub-Component-UpdateProfile">
            <UpdateProfile />
          </div>
          <div className="margin-div"></div>
        </>
      ) : (
        <>
          <h1>Checkout</h1>
          <div id="Sub-Component-Checkout">
            <Checkout
              onSuccess={() => refreshPage()}
              onClick={() => console.log("clicked")}
            />
          </div>

          <h1>Register</h1>
          <div id="Sub-Component-Register">
            <Register
              onSuccess={() => refreshPage()}
              onHaveAccountClick={() => console.log("have an account clicked")}
            />
          </div>

          <h1>Login</h1>
          <div id="Sub-Component-Login">
            <Login
              onSuccess={() => refreshPage()}
              onRegisterClick={() => console.log("register button clicked")}
              onPasswordResetClick={() =>
                console.log("password reset button clicked")
              }
            />
          </div>

          <h1>PasswordReset</h1>
          <div id="Sub-Component-PasswordReset">
            <PasswordReset onSuccess={() => console.log("success")} />
          </div>

          <div className="margin-div"></div>
        </>
      )}
    </div>
  );
};

OnePageMS.propTypes = {
  offer: PropTypes.string,
};

export default OnePageMS;
